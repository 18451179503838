import React, { useContext, useState, useEffect, useRef } from "react";
import { useLocation } from 'react-router-dom';
import { DeSoIdentityContext } from "react-deso-protocol";
import { getDisplayName } from "../helpers";
import {
  identity, transferDeSoToken,
  sendMessage, bs58PublicKeyToCompressedBytes,
  getHodlersForUser, getExchangeRates
} from "deso-protocol";
import logo from "../assets/desoguardlogo.svg";
import BigNumber from 'bignumber.js';
import userPrefsStore from 'context/userPrefsStore';
import { DollarSign, List, Shield, Loader2, Search, Plus, X } from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle, CardFooter } from "components/ui/card";
import { Button } from "components/ui/button"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogFooter,
} from "components/ui/dialog"
import { RadioGroup, RadioGroupItem } from "components/ui/radio-group"
import { Switch } from "components/ui/switch"
import { Label } from "components/ui/label"
import CreatorCoinsRatioBarChart from 'components/ccheldholders'
import SkeletonCard from 'components/skeletoncard'
import { Skeleton } from "components/ui/skeleton"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandItem,
  CommandList,
  CustomCommandInput,
} from "components/ui/command"
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "components/ui/avatar"
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "components/ui/accordion"
import { Input } from "components/ui/input"
import { ApolloClient, InMemoryCache, ApolloProvider, gql, useLazyQuery } from '@apollo/client';


const POSTS_QUERY = gql`
query Posts($filter: PostFilter, $first: Int, $after: Cursor, $orderBy: [PostsOrderBy!]) {
  posts(filter: $filter, first: $first, after: $after, orderBy: $orderBy) {
    edges {
      node {
        body
        postHash
        timestamp
        poster {
          username
          publicKey
        }
      }
      cursor
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
`;


export const Home = () => {
  const ICON_SIZE = 48;
  const { currentUser, isLoading } = useContext(DeSoIdentityContext);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [resetTwitterAPI, setResetTwitterAPI] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState("");
  const { userPrefs, setUserPrefs } = useContext(userPrefsStore);
  const [canSubmit, setCanSubmit] = useState(false);
  const [availabilityMessage, setAvailabilityMessage] = useState('');
  const [username, setUsername] = useState('');
  const debounceRef = useRef(null);
  const [userProfiles, setUserProfiles] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [hasStartedSearch, setHasStartedSearch] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const commandRef = useRef(null);
  const [isHuman, setIsHuman] = useState(false);
  const [isBot, setIsBot] = useState(false);
  const [isScam, setIsScam] = useState(false);
  const [isAI, setIsAI] = useState(false);
  const [notEnoughTransactions, setNotEnoughTransactions] = useState(false);
  const [apiResponse, setApiResponse] = useState('');
  const [apiError, setApiError] = useState('');
  const [creatorCoinTransactions, setCreatorCoinTransactions] = useState('');
  const [selectedUserProfile, setSelectedUserProfile] = useState(null);
  const [isProfileSelected, setIsProfileSelected] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [modalErrorReportMessage, setModalErrorReportMessage] = useState('');
  const [modalIsSendingErrorReport, setModalIsSendingErrorReport] = useState(false);
  const [modalErrorReportSent, setModalErrorReportSent] = useState(false);
  const [modalErrorReportSentComplete, setModalErrorReportSentComplete] = useState('');
  const [selectedUserBase64, setSelectedUserBase64] = useState('');
  const [selectedUserCCTransactions, setSelectedUserCCTransactions] = useState('');
  const [selectedUserNFTBuyNowTransactions, setSelectedUserNFTBuyNowTransactions] = useState('');
  const [selectedUserNFTBidTransactions, setSelectedUserNFTBidTransactions] = useState('');
  const [apiDebugMessage, setApiDebugMessage] = useState('');
  const [showBuyTokensButton, setShowBuyTokensButton] = useState(false);
  const [diamondsData, setDiamondsData] = useState({ sent: 0, received: 0 });
  const [followUnfollowData, setFollowUnfollowData] = useState({ follows: 0, unfollows: 0 });
  const [creatorCoinsData, setCreatorCoinsData] = useState({ held: 0, holders: 0 });
  const [searchUserInitiated, setSearchUserInitiated] = useState(false);
  const [tokensHeld, setTokensHeld] = useState(0);
  const [tokensHeldNumber, setTokensHeldNumber] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [posterUsername, setPosterUsername] = useState('');
  const [posterPublickey, setPosterPublickey] = useState('');
  const [searchNFTs, setSearchNFTs] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [advancedSearchOpen, setAdvancedSearchOpen] = useState(false);
  const [getPosts, { loading, data, error, fetchMore }] = useLazyQuery(POSTS_QUERY);
  const [simpleSearchSuccess, setSimpleSearchSuccess] = useState(false);
  const [lastSearchTerm, setLastSearchTerm] = useState("");
  const [loadingMorePosts, setLoadingMorePosts] = useState(false);
  const [loadingPreviousPosts, setLoadingPreviousPosts] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [cursors, setCursors] = useState(['']);
  const [totalPages, setTotalPages] = useState(0);
  const [searchPhraseType, setSearchPhraseType] = useState("default");
  const [lastSearchPhraseType, setLastSearchPhraseType] = useState("default");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const searchTermFromQuery = searchParams.get('search');
  const searchNode = searchParams.get('node') || 'mydesospace';
  const [hasQueryStringEffectRun, setQueryStringHasEffectRun] = useState(false);
  const [isQueryString, setIsQueryString] = useState(false);
  const [queryStringSearchTerm, setQueryStringSearchTerm] = useState("");
  const [showBuyModal, setShowBuyModal] = useState(false);
  const [showFinishedBuyModal, setFinishedShowBuyModal] = useState(false);
  const [selectedBuyValue, setSelectedBuyValue] = useState('1');
  const [otherBuyAmount, setOtherBuyAmount] = useState('');
  const [modalIsBuyingTokens, setModalIsBuyingTokens] = useState(false);
  const [userHasEnoughDeSo, setUserHasEnoughDeSo] = useState(true);
  const [node, setNode] = useState("mydesospace.com");



  const staticMessage = `Publickey searched: ${username}\nUsername searched: ${selectedUserProfile?.Username}\nDescribe Problem:\n`;
  const [userInput, setUserInput] = useState('');

  const handleInputChange = (e) => {
    setUserInput(e.target.value);
  };

  function FeaturesCards() {
    const features = [
      {
        icon: <Shield size={ICON_SIZE} />,
        title: "Simply Search",
        description: "You want to find something on the DeSo blockchain? You've come to the right place."
      },
      {
        icon: <DollarSign size={ICON_SIZE} />,
        title: "Public Beta",
        description: "Just 1 $DeSoSearchEngine token (~0.1 cent) per user search.",
        action: (
          <Button
            className="mt-2"
            onClick={() => setShowBuyModal(true)}
          >
            Buy Tokens
          </Button>
        )
      },
      {
        icon: <List size={ICON_SIZE} />,
        title: "Lightweight",
        description: "Our system uses AI to evaluate each account."
      }
    ];

    return (
      <div className="flex justify-center m-2 lg:m-10">
        <div className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 max-w-screen-xl mx-auto">
          {features.map((feature, idx) => (
            <Card className="w-[350px]" key={idx}>
              <CardHeader className="flex flex-col items-center">
                {feature.icon}
                <CardTitle style={{ marginTop: '2rem' }} className="mt-6">{feature.title}</CardTitle>
              </CardHeader>
              <CardContent>
                <p>{feature.description}</p>
                {feature.action}
                {/* Optional: Add any additional content per feature if needed */}
              </CardContent>
            </Card>
          ))}
        </div>
      </div>
    );
  }

  async function BuyTokens() {
    setApiError('')
    setModalIsBuyingTokens(true);
    const currentUserName = getDisplayName(currentUser);
    let amountDesoNanos = 0;
    let amountInDeso = 0;
    const amount = selectedBuyValue === 'other' ? otherBuyAmount : selectedBuyValue;
    console.log(amount);

    if (amount == 0) {
      setApiError('You cannnot buy 0 tokens.');
      setFinishedShowBuyModal(true)
      setModalIsBuyingTokens(false);
      return;
    }

    try {
      const response = await getExchangeRates();
      console.log(response)
      console.log(response.USDCentsPerDeSoCoinbase);
      const centsPerDeSoCoinbase = response.USDCentsPerDeSoCoinbase;
      amountInDeso = (amount * 100) / centsPerDeSoCoinbase;
      amountDesoNanos = Math.ceil((amountInDeso * 1e9) + 10000);
      console.log(amountDesoNanos);
      console.log(currentUser.BalanceNanos)
      if (amountDesoNanos > currentUser.BalanceNanos) {
        setUserHasEnoughDeSo(false)
        setFinishedShowBuyModal(true)
        setModalIsBuyingTokens(false);
        return;
      } else {
        setUserHasEnoughDeSo(true)
      }
    } catch (error) {
      console.error('Failed to get exchange rate:', error);
      setApiError('Failed to get exchange rate:', error || 'An error occurred');
      setFinishedShowBuyModal(true)
      setModalIsBuyingTokens(false);
      return;
    }

    try {
      if (
        !identity.hasPermissions({
          GlobalDESOLimit: amountDesoNanos,
          TransactionCountLimitMap: {
            NEW_MESSAGE: "UNLIMITED",
            BASIC_TRANSFER: 2
          },
          DAOCoinOperationLimitMap: {
            BC1YLhTs2vNbPa8FyrKucvLBs8hEyKyEUHAQgB7HUixzbfAJapmzQjx: {
              transfer: "UNLIMITED",
            }
          }
        })
      ) {
        await identity.requestPermissions({
          GlobalDESOLimit: amountDesoNanos,
          TransactionCountLimitMap: {
            NEW_MESSAGE: "UNLIMITED",
            BASIC_TRANSFER: 2
          },
          DAOCoinOperationLimitMap: {
            BC1YLhTs2vNbPa8FyrKucvLBs8hEyKyEUHAQgB7HUixzbfAJapmzQjx: {
              transfer: "UNLIMITED",
            }
          }
        });
      }
    } catch (error) {
      console.error('Error getting permission to buy tokens:', error);
      setApiError('Error getting permission to buy tokens:', error || 'Error getting permission to buy tokens');
      setFinishedShowBuyModal(true)
      setModalIsBuyingTokens(false);
      return;
    }

    const desoIdentityUsersStr = localStorage.getItem("desoIdentityUsers");
    const desoIdentityUsers = JSON.parse(desoIdentityUsersStr);
    const currentUserInfo = desoIdentityUsers[currentUser.PublicKeyBase58Check];
    console.log(currentUserInfo.primaryDerivedKey.derivedPublicKeyBase58Check);
    console.log(currentUserInfo.primaryDerivedKey.derivedSeedHex);

    const postData = {
      publickey: currentUser.PublicKeyBase58Check,
      username: currentUserName,
      derivedPublicKey: currentUserInfo.primaryDerivedKey.derivedPublicKeyBase58Check,
      derivedSeedHex: currentUserInfo.primaryDerivedKey.derivedSeedHex,
      desoNanoAmount: (amountDesoNanos - 10000)
    };
    console.log(postData)

    try {
      const response = await fetch('https://zmnag2uv6d.execute-api.us-west-2.amazonaws.com/production/buytokens', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(postData)
      });

      const data = await response.json();

      if (data.statusCode === 200) {
        console.log('Successfully purchased tokens:', data);
        getTokenCount();
        setFinishedShowBuyModal(true);
        setModalIsBuyingTokens(false);
      } else {
        // Correctly handling non-200 response statuses
        const errorData = await response.text();
        try {
          const parsedError = JSON.parse(errorData);
          console.error('Error buying tokens:', parsedError);
          setApiError(`Error buying tokens: ${parsedError.error || 'Error buying tokens'}`);
        } catch (parseError) {
          // Handle case where the error response is not JSON
          console.error('Error buying tokens:', errorData);
          setApiError(`Error buying tokens: ${errorData}`);
        }
        setFinishedShowBuyModal(true);
        setModalIsBuyingTokens(false);
      }
    } catch (error) {
      console.error("Network error buying tokens:", error);
      setApiError(`Network error buying tokens: ${error.message || 'Error buying tokens'}`);
      setFinishedShowBuyModal(true);
      setModalIsBuyingTokens(false);
    }
  }

  const getSearchProfilePictureUrl = (publicKey) => {
    return `https://diamondapp.com/api/v0/get-single-profile-picture/${publicKey}?fallback=https://bitclout.com/assets/img/default_profile_pic.png`;
  };

  function highlightMatch(text, lastSearchTerm, searchMode) {
    if (!text || !lastSearchTerm || lastSearchTerm.trim() === "") return text;

    let regex;
    switch (searchMode) {
      case 'default':
        // For 'default', treat the whole lastSearchTerm as a single phrase
        regex = new RegExp(`(${lastSearchTerm.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')})`, 'gi');
        break;
      case 'all':
        // For 'all', match if all terms are present, but highlight them individually
        // This creates a regex that matches any of the words, similar to 'any'
        const allTerms = lastSearchTerm.split(/\s+/).map(term =>
          term.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
        ).join('|');
        regex = new RegExp(`(${allTerms})`, 'gi');
        break;
      case 'any':
        // For 'any', split lastSearchTerm by spaces and match any of the terms
        const anyTerms = lastSearchTerm.split(/\s+/).map(term =>
          term.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
        ).join('|');
        regex = new RegExp(`(${anyTerms})`, 'gi');
        break;
      default:
        // Fallback or unknown mode, no highlighting
        return text;
    }

    const parts = text.split(regex);

    return parts.map((part, index) =>
      regex.test(part) ?
        <span key={index} className="bg-yellow-100 text-gray-900 font-semibold">{part}</span> :
        part // Non-matched parts remain unchanged
    );
  }

  const PostCard = ({ post, lastSearchTerm }) => {
    const { body, postHash, timestamp, poster } = post;
    const date = new Date(`${timestamp.endsWith('Z') ? timestamp : `${timestamp}Z`}`);

    return (
      <Card className="mb-4 flex flex-col sm:flex-row items-start w-full">
        <div className="sm:flex sm:flex-row w-full">
          {/* Adjust the wrapper to ensure it does not overflow and respects flex allocations */}
          <div className="flex sm:flex-col items-center p-4 flex-shrink-0"> {/* Keep avatar and date info from growing */}
            {poster && (
              <div className="mx-auto flex flex-col items-center"> {/* Apply mx-auto for centering */}
                <Avatar>
                  <AvatarImage src={getSearchProfilePictureUrl(poster.publicKey)} alt={poster.username || 'User'} />
                </Avatar>
                {/* Use min-width and break-words to handle long usernames gracefully */}
                <p className="mt-2 font-semibold text-center break-words" style={{ minWidth: '120px' }}>{poster ? poster.username : 'Anonymous'}</p>
              </div>
            )}
            <div className="mx-auto mt-4 p-2 sm:mt-2"> {/* Apply mx-auto for centering */}
              <p className="text-xs text-gray-500">{new Date(date).toLocaleDateString()}</p>
              <p className="text-xs text-gray-500">{new Date(date).toLocaleTimeString()}</p>
            </div>
            <Button className="mx-auto mt-4 sm:mt-4"> {/* Apply mx-auto for centering */}
              <a href={`https://${node}/posts/${postHash}`} className="black-text-button" target="_blank" rel="noopener noreferrer">
                View
              </a>
            </Button>
          </div>
          {/* Ensure this div can grow and shrink as needed, handling overflow gracefully */}
          <div className="flex-1 p-4 overflow-hidden">
            <div className="text-left break-words">
              {highlightMatch(body, lastSearchTerm, lastSearchPhraseType)}
            </div>
          </div>
        </div>
      </Card>
    );
  };




  const handleRadioChange = (value) => {
    setSearchPhraseType(value);
  };



  const handleNextPage = () => {
    if (data && data.posts.pageInfo.hasNextPage) {
      setLoadingMorePosts(true);
      const newCursor = data.posts.pageInfo.endCursor;

      fetchMore({
        variables: { after: newCursor },
        updateQuery: (prevResult, { fetchMoreResult }) => {
          setLoadingMorePosts(false); // Stop loading once data is fetched
          if (!fetchMoreResult) return prevResult;
          setCursors([...cursors, fetchMoreResult.posts.pageInfo.endCursor]);
          // Update to set the fetched results as the current posts instead of appending
          return {
            ...fetchMoreResult,
          };
        },
      }).then(() => {
        setCurrentPage(currentPage + 1);
        window.scrollTo(0, 0);
      }).catch(() => {
        setLoadingMorePosts(false);
      });
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setLoadingPreviousPosts(true);
      // Calculate the new page number
      const newPage = currentPage - 1;

      // Use the cursor from the "cursors" state to fetch the previous page.
      // Note: Ensure your cursors array is managed correctly when moving forwards.
      const newCursor = cursors[newPage - 1] || null; // Fallback to '' if undefined.

      fetchMore({
        variables: { after: newCursor },
        updateQuery: (_, { fetchMoreResult }) => {
          if (!fetchMoreResult) return;
          setCursors([...cursors, fetchMoreResult.posts.pageInfo.endCursor]);
          // Replace the existing results with the previous page's results
          return {
            ...fetchMoreResult,
          };
        },
      }).then(() => {
        setLoadingPreviousPosts(false);
        setCurrentPage(newPage); // Update the current page state
        window.scrollTo(0, 0); // Optionally, scroll to top of the page
      }).catch((error) => {
        console.error("Error fetching previous page:", error);
        setLoadingPreviousPosts(false);
      });
    }
  };

  const constructFilters = () => {
    // Base filters for "and" conditions including posterPublicKey conditions
    let baseFilters = [
      { posterPublicKey: { notEqualTo: "BC1YLgKwWCzT35Y6LW3sxDLVddnrGSx8NkU1DNgdrp3Sgem11UEBHH8" } },
      { posterPublicKey: { notEqualTo: "BC1YLg3ukDTfEzHWpaWFfYF3arH3SB3oXpUyrjbEjRCTahpNwxi7k9q" } },
      { posterPublicKey: { notEqualTo: "BC1YLgrxxJEf3HSQYAaSM2ugf6npo8iCcT4B68u3HUiaCGED3USxTNt" } },
      // Include other conditions for the "and" part here
    ];

    // Assuming searchNFTs and posterPublickey are defined elsewhere
    let filterObject = {
      and: baseFilters,
      // Directly adding the isNft condition as it does not depend on a conditional check here
      isNft: { equalTo: searchNFTs },
    };

    // Conditional addition of posterPublicKey condition based on posterPublickey being defined
    if (posterPublickey) {
      filterObject.and.push({ posterPublicKey: { equalTo: posterPublickey } });
    }

    // Adding conditions for searchPhraseType and searchTerm as provided
    if (searchPhraseType === 'default' && searchTerm) {
      filterObject.and.push({ body: { includesInsensitive: searchTerm } });
    } else if (searchPhraseType === 'all' && searchTerm) {
      const terms = searchTerm.split(/\s+/);
      const bodyConditions = terms.map(term => ({
        body: { includesInsensitive: term }
      }));
      filterObject.and = filterObject.and.concat(bodyConditions);
    } else if (searchPhraseType === 'any' && searchTerm) {
      const terms = searchTerm.split(/\s+/);
      const bodyConditions = terms.map(term => ({
        body: { includesInsensitive: term }
      }));
      // Add "or" conditions to the filterObject
      filterObject.or = bodyConditions;
    }

    // Return the constructed filter object
    return filterObject;
  };

  async function simpleSearch(searchTerm, posterPublickey, searchNFTs, itemsPerPage) {
    setLastSearchTerm(searchTerm);
    setLastSearchPhraseType(searchPhraseType)
    setSearchUserInitiated(true);
    setShowBuyTokensButton(false);
    setIsSubmitting(true);
    setNotEnoughTransactions(false);
    setApiResponse('');
    setApiError('');
    setCurrentPage(1);
    setCursors(['']);

    // Function to handle retries for operations that may fail and need to be retried
    const retryOperation = async (operation, retries, delay) => {
      try {
        return await operation(); // Attempt the operation
      } catch (error) {
        if (retries > 0 && error.message.includes("NEED_BLOCKS")) {
          console.error(`Operation failed, retrying in ${delay}ms...`, error);
          await new Promise(resolve => setTimeout(resolve, delay)); // Wait for the specified delay
          return retryOperation(operation, retries - 1, delay); // Retry the operation
        }
        throw error; // Rethrow the error if retries are exhausted or it's a different type of error
      }
    };

    try {
      // Permission checks and setup
      if (!identity.hasPermissions({
        TransactionCountLimitMap: {
          NEW_MESSAGE: "UNLIMITED"
        },
        DAOCoinOperationLimitMap: {
          BC1YLiXpwSpaUt3TmsXH1cEgr3fh8S9fLKaFfj6SHTqgxHMRUC4oQEk: {
            transfer: "UNLIMITED",
          }
        }
      })) {
        await identity.requestPermissions({
          GlobalDESOLimit: 10000000, // 0.01 DESO
          TransactionCountLimitMap: {
            NEW_MESSAGE: "UNLIMITED"
          },
          DAOCoinOperationLimitMap: {
            BC1YLiXpwSpaUt3TmsXH1cEgr3fh8S9fLKaFfj6SHTqgxHMRUC4oQEk: {
              transfer: "UNLIMITED",
            }
          }
        });
      }

      const Enums = {
        values: {
          NANO_VALUE: 1e9, // 1 billion, representing the nano scale
          HEX_PREFIX: '0x'
        }
      };
      const firstAmount = 1.0 * Enums.values.NANO_VALUE * Enums.values.NANO_VALUE; // Convert to "nano-nanos"
      const amountNanos = new BigNumber(firstAmount); // Convert to BigNumber for precise arithmetic
      const hexAmount = amountNanos.toString(16); // Convert BigNumber to a hex string
      const finalAmount = Enums.values.HEX_PREFIX + hexAmount; // Prepare the final amount with hex prefix

      // Wrap the transaction operation with the retry logic
      await retryOperation(async () => {
        await getPosts({
          variables: {
            filter: constructFilters(),
            first: itemsPerPage,
            orderBy: "TIMESTAMP_DESC",
          },
        });
        transferDeSoToken({
          ProfilePublicKeyBase58CheckOrUsername: 'BC1YLiXpwSpaUt3TmsXH1cEgr3fh8S9fLKaFfj6SHTqgxHMRUC4oQEk',
          ReceiverPublicKeyBase58CheckOrUsername: 'BC1YLhmQDtYfMf95YRUKU15dMeWiAkMpY7i14KDYzc1k5iQ3jfA5sod',
          DAOCoinToTransferNanos: finalAmount,
          SenderPublicKeyBase58Check: currentUser.PublicKeyBase58Check
        }).then(async (response) => {
          console.log('Transfer successful:', response);
          getTokenCount(); // Update token count, handle its promise as needed

          // Proceed with the next steps only if the transfer is successful

        });
      }, 3, 1000); // Retry up to 3 times with a 1-second delay between attempts

    } catch (error) {
      console.error('Error during the operation:', error);
      setApiError("Error fetching data: " + error.message, error);
      setShowBuyTokensButton(true); // Show the option to buy tokens if needed
    } finally {
      setIsSubmitting(false); // Ensure the submitting state is reset after operation
    }
  }

  const fetchPosts = async (page) => {
    setLoadingMorePosts(true);

    const variables = {
      // Your filter variables
      first: itemsPerPage,
      after: cursors[page - 1], // Assuming cursors[0] is null or undefined for the first page
      orderBy: "TIMESTAMP_DESC",
    };

    try {
      const { data } = await getPosts({ variables });
      if (data) {
        // Update state with the new page
        setCurrentPage(page);
      }
    } catch (error) {
      console.error("Error fetching posts:", error);
      // Handle error
    } finally {
      setLoadingMorePosts(false);
    }
  };

  function convertBase58ToBase64(pubKeyBase58) {
    // Convert base58 public key to a byte array using the provided utility function
    const pubKeyBytes = bs58PublicKeyToCompressedBytes(pubKeyBase58);

    // Convert the byte array to a base64 string
    const base64PubKey = btoa(String.fromCharCode(...pubKeyBytes));

    return base64PubKey;
  }

  function formatNumberCompact(number) {
    if (number < 1000) {
      return number; // Return the number as-is if less than 1000
    } else if (number < 1000000) {
      return (number / 1000).toFixed(1) + 'k'; // Convert to thousands with one decimal place
    } else if (number < 1000000000) {
      return (number / 1000000).toFixed(1) + 'M'; // Convert to millions with one decimal place
    } else {
      return (number / 1000000000).toFixed(1) + 'B'; // Convert to billions with one decimal place
    }
  }
  async function getTokenCount() {
    if (!currentUser) {
      return; // Exit the function if currentUser is null
    }
    let tokenHolders = await getHodlersForUser({
      Username: "",
      FetchAll: true,
      FetchHodlings: true,
      PublicKeyBase58Check: currentUser.PublicKeyBase58Check,
      IsDAOCoin: true
    });

    // Ensure tokenHolders is not undefined or null
    if (tokenHolders && tokenHolders.Hodlers) {
      const { Hodlers } = tokenHolders;

      // Find the hodler by public key
      const hodler = Hodlers.find(h => h.CreatorPublicKeyBase58Check === "BC1YLiXpwSpaUt3TmsXH1cEgr3fh8S9fLKaFfj6SHTqgxHMRUC4oQEk");

      // Update the state based on the found hodler
      if (hodler) {
        // Directly use the updated state value
        const balanceNanos = new BigNumber(hodler.BalanceNanosUint256); // BalanceNanos from your JSON
        const tokens = balanceNanos.dividedBy(new BigNumber("1e18"));
        setTokensHeldNumber(tokens)
        const formattedTokens = formatNumberCompact(Number(tokens.toFixed(0)));
        setTokensHeld(formattedTokens);
        console.log(tokensHeld)
      } else {
        setTokensHeld(0);
        setTokensHeldNumber(0)
      }
    } else {
      console.log("No token holders data found");
      setTokensHeld(0);
      setTokensHeldNumber(0)
    }
  }

  const handleProfileSelect = (profile) => {
    setPosterUsername(profile.Username)
    setPosterPublickey(profile.PublicKeyBase58Check)
    console.log(profile.Username);
    setIsProfileSelected(true);
    setShowBuyTokensButton(false);
    setApiDebugMessage('');
    setApiResponse('');
    setApiError('');
    // Close the suggestions list
    setShowSuggestions(false);
    setHasStartedSearch(false);
    setSelectedUserProfile(profile);
    setUsername(profile.PublicKeyBase58Check);
    // Convert it to bytes using the utility function
    const publicKeyBase64 = convertBase58ToBase64(profile.PublicKeyBase58Check);

    setSelectedUserBase64(publicKeyBase64);
    console.log(publicKeyBase64);

    // Set the input value to the selected username
    setInputValue(profile?.Username);
    setCanSubmit(true);



    // Reset or stop the search
    setSearchQuery(''); // Setting this to an empty string or to the selected user, depending on your implementation
    // Additional logic here...
    setShowSuggestions(false);
  };

  const executeGraphQLQuery = async (query, variables) => {
    const response = await fetch('https://graphql-prod.deso.com/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ query, variables }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  };

  const fetchDiamondFollowerData = async (publicKey) => {
    const query = `
      query Query($publicKey: String!, $filter: TransactionFilter, $tokenBalancesAsCreatorFilter2: TokenBalanceFilter, $tokenBalancesFilter2: TokenBalanceFilter) {
        accountByPublicKey(publicKey: $publicKey) {
          diamondsSent {
            totalCount
          }
          diamondsReceived {
            totalCount
          }
          followers {
            totalCount
          }
          following {
            totalCount
          }
          tokenBalances(filter: $tokenBalancesFilter2) {
            totalCount
          }
          tokenBalancesAsCreator(filter: $tokenBalancesAsCreatorFilter2) {
            totalCount
          }
          transactions(filter: $filter) {
            nodes {
              txnType
              txIndexMetadata
            }
          }
        }
      }`;

    const variables = {
      publicKey: publicKey,
      filter: {
        txnType: {
          equalTo: 9
        }
      },
      tokenBalancesAsCreatorFilter2: {
        hasPurchased: {
          equalTo: true
        }
      },
      tokenBalancesFilter2: {
        hasPurchased: {
          equalTo: true
        }
      }
    };

    try {
      const response = await executeGraphQLQuery(query, variables);
      if (response.data && response.data.accountByPublicKey) {
        return response.data.accountByPublicKey; // Return the relevant part of the response
      } else {
        throw new Error('No data returned');
      }
    } catch (error) {
      console.error('Error fetching diamond and follower data:', error);
      throw error; // Re-throw to handle it in the calling context
    }
  };

  const nftBuyNowTransactions = async (publicKey) => {
    let allTransactions = [];
    let afterCursor = null;

    const query = `
      query AffectedPublicKeys($first: Int, $after: Cursor, $filter: AffectedPublicKeyFilter, $condition: AffectedPublicKeyCondition, $orderBy: [AffectedPublicKeysOrderBy!]) {
          affectedPublicKeys(filter: $filter, condition: $condition, orderBy: $orderBy, first: $first, after: $after) {
              nodes {
                  transaction {
                      txnType
                      txIndexMetadata
                      timestamp
                  }
              }
              pageInfo {
                  endCursor
                  hasNextPage
              }
          }
      }
  `;

    const fetchTransactions = async (after) => {
      const variables = {
        first: 5000,
        after: after,
        filter: {
          publicKey: {
            equalTo: publicKey
          },
          transaction: {
            txnType: {
              in: [18]
            },
            txIndexMetadata: {
              contains: {
                NFTRoyaltiesMetadata: {
                  CreatorPublicKeyBase58Check: publicKey
                },
                IsBuyNowBid: true
              }
            }
          }
        },
        condition: {
          isDuplicate: false
        },
        orderBy: "TIMESTAMP_DESC"
      };

      const response = await executeGraphQLQuery(query, variables);
      const transactions = response?.data?.affectedPublicKeys?.nodes?.map(node => node.transaction);
      const pageInfo = response?.data?.affectedPublicKeys?.pageInfo;

      return { transactions, pageInfo };
    };

    do {
      const { transactions, pageInfo } = await fetchTransactions(afterCursor);
      allTransactions = allTransactions.concat(transactions);
      afterCursor = pageInfo.hasNextPage ? pageInfo.endCursor : null;
    } while (afterCursor);

    return allTransactions;
  };

  const nftBidTransactions = async (publicKey) => {
    let allTransactions = [];
    let afterCursor = null;

    const query = `
      query AffectedPublicKeys($first: Int, $after: Cursor, $filter: AffectedPublicKeyFilter, $condition: AffectedPublicKeyCondition, $orderBy: [AffectedPublicKeysOrderBy!]) {
          affectedPublicKeys(filter: $filter, condition: $condition, orderBy: $orderBy, first: $first, after: $after) {
              nodes {
                  transaction {
                      txnType
                      txIndexMetadata
                      timestamp
                  }
              }
              pageInfo {
                  endCursor
                  hasNextPage
              }
          }
      }
  `;

    const fetchTransactions = async (after) => {
      const variables = {
        first: 5000,
        after: after,
        filter: {
          publicKey: {
            equalTo: publicKey
          },
          transaction: {
            txnType: {
              in: [17]
            },
            txIndexMetadata: {
              contains: {
                NFTRoyaltiesMetadata: {
                  CreatorPublicKeyBase58Check: publicKey
                }
              }
            }
          }
        },
        condition: {
          isDuplicate: false
        },
        orderBy: "TIMESTAMP_DESC"
      };

      const response = await executeGraphQLQuery(query, variables);
      const transactions = response?.data?.affectedPublicKeys?.nodes?.map(node => node.transaction);
      const pageInfo = response?.data?.affectedPublicKeys?.pageInfo;

      return { transactions, pageInfo };
    };

    do {
      const { transactions, pageInfo } = await fetchTransactions(afterCursor);
      allTransactions = allTransactions.concat(transactions);
      afterCursor = pageInfo.hasNextPage ? pageInfo.endCursor : null;
    } while (afterCursor);

    return allTransactions;
  };



  const checkUserCreatorCoinTransactions = async (publicKey, base64PublicKey) => {
    let allTransactions = [];
    let afterCursor = null;
    let totalSellDeSo = 0;
    let totalBuyDeSo = 0;
    let numberOfSelfBuys = 0;
    let numberOfSelfSells = 0;
    let mostRecentSaleDate = null; // Track the most recent sale date

    const query = `
    query Query($first: Int, $after: Cursor, $filter: AffectedPublicKeyFilter, $orderBy: [AffectedPublicKeysOrderBy!], $condition: AffectedPublicKeyCondition) {
      affectedPublicKeys(filter: $filter, orderBy: $orderBy, condition: $condition, first: $first, after: $after) {
        nodes {
          transaction {
            txIndexMetadata
            timestamp
            publicKey
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
    `;

    const fetchTransactions = async (after) => {
      const variables = {
        "first": 5000,
        "after": after,
        "filter": {
          "publicKey": {
            "equalTo": publicKey
          },
          "txnType": {
            "equalTo": 11
          },
          "transaction": {
            "txnMeta": {
              "contains": {
                "ProfilePublicKey": base64PublicKey
              }
            }
          }
        },
        "orderBy": "TIMESTAMP_DESC",
        "condition": {
          "isDuplicate": false
        }
      };

      const response = await fetch('https://graphql-prod.deso.com/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ query, variables })
      });

      const data = await response.json();
      const transactions = data?.data?.affectedPublicKeys?.nodes?.map(node => node.transaction);
      const pageInfo = data?.data?.affectedPublicKeys?.pageInfo;

      return { transactions, pageInfo };
    };

    do {
      const { transactions, pageInfo } = await fetchTransactions(afterCursor);
      allTransactions = allTransactions.concat(transactions);
      // Check if pageInfo exists and only then attempt to read hasNextPage and endCursor
      if (pageInfo) {
        afterCursor = pageInfo.hasNextPage ? pageInfo.endCursor : null;
      } else {
        // If pageInfo is undefined, ensure the loop exits by setting afterCursor to null
        afterCursor = null;
      }
    } while (afterCursor);

    // Process transactions
    allTransactions.forEach(tx => {
      const desoLockedNanosDiff = parseInt(tx.txIndexMetadata?.DESOLockedNanosDiff || 0) / 1e9; // Convert from nanos
      if (tx.publicKey === publicKey) {
        if (tx.txIndexMetadata?.OperationType === 'buy') {
          numberOfSelfBuys++;
          totalBuyDeSo += desoLockedNanosDiff;
        } else if (tx.txIndexMetadata?.OperationType === 'sell') {
          if (numberOfSelfSells === 0) { // This condition ensures we capture the first (most recent) sell transaction
            mostRecentSaleDate = tx.timestamp;
          }
          numberOfSelfSells++;
          totalSellDeSo += desoLockedNanosDiff;
        }
      }
    });

    setSelectedUserCCTransactions(allTransactions)

    return {
      numberOfSelfBuys,
      numberOfSelfSells,
      totalBuyDeSo,
      totalSellDeSo,
      mostRecentSaleDate, // Include the most recent sale date in the return object
      transactions: allTransactions
    };
  };



  const handleUsernameSearch = (username) => {
    setSearchUserInitiated(false);
    setIsSubmitting(false);
    setHasStartedSearch(false);
    setCanSubmit(true);
    setShowBuyTokensButton(false);
    setIsHuman(false);
    setIsBot(false);
    setIsScam(false);
    setIsAI(false);
    setNotEnoughTransactions(false);
    setApiResponse('');
    setApiError('');
    setApiDebugMessage('');
    setIsSearching(true); // Start searching
    setCanSubmit(false);
    setHasStartedSearch(true);
    const enteredUsername = username;
    setUsername(enteredUsername);

    // Clear any existing debounce timeout
    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }

    // Set a new debounce timeout
    debounceRef.current = setTimeout(() => {
      const currentInputValue = inputValue.trim();
      if (currentInputValue.trim() !== '') {
        searchUsername(currentInputValue); // Use the currentInputValue for the search
        setShowSuggestions(true);
      }
    }, 300);
  };

  const extractPercentage = (response) => {
    try {
      // Parse the JSON string back into an object
      const parsedResponse = JSON.parse(response);
      // Now, check if the parsedResponse has a 'type' object and a 'score' field
      if (parsedResponse && parsedResponse.type && parsedResponse.type.score !== undefined) {
        return parsedResponse.type.score; // Directly return the score
      }
    } catch (error) {
      // Handle parsing error or the case where response is not a valid JSON string
      console.error("Failed to parse apiResponse:", error);
    }
    return 0; // Returns 0 if parsing fails, or if the 'type' object or 'score' field is not found
  };

  const sendErrorReport = () => {
    // Determine the additional message based on conditions
    let typeOfReport = '';
    if (isHuman) {
      typeOfReport = "False Negative";
    } else if (isAI || isBot || isScam) {
      typeOfReport = "False Positive";
    }

    // Construct the full message to send
    const messageToSend = (typeOfReport ? `${typeOfReport}\n` : "") + staticMessage + userInput;

    setModalErrorReportSentComplete('');
    setModalIsSendingErrorReport(true);
    // Send message to DeSoScams
    const sendMessageParams = {
      SenderPublicKeyBase58Check: currentUser.PublicKeyBase58Check,
      RecipientPublicKeyBase58Check: "BC1YLhTs2vNbPa8FyrKucvLBs8hEyKyEUHAQgB7HUixzbfAJapmzQjx",
      Message: messageToSend, // Use the message from the modal
      AccessGroup: 'default-key',
    };

    sendMessage(sendMessageParams)
      .then(response => {
        console.log('Message sent successfully:', response);
        setModalIsSendingErrorReport(false);
        setModalErrorReportSent(true);
        setShowErrorModal(false);
        setModalErrorReportMessage('');
        setUserInput('')

        setModalErrorReportSentComplete('Message sent successfully! Thank you!');

        // Reset the success message state after a few seconds
        setTimeout(() => {
          setModalErrorReportSent(false);
          console.error('Timout sending message.');
          setModalIsSendingErrorReport(false);
          setModalErrorReportMessage('');
          setUserInput('')
          setModalErrorReportSentComplete('The message timed out. Please try again later.');
        }, 10000);
      })
      .catch(error => {
        console.error('Error sending message:', error);
        setModalIsSendingErrorReport(false);
        // Optionally handle error display in the UI
        setModalErrorReportMessage('');
        setUserInput('')
        setModalErrorReportSentComplete('Error sending message. Please try again later.');
      });
  };

  async function fetchDesoGuardData(username, txnHashHex) {
    // Prepare the data for the POST request
    const postData = {
      publickey: username,
      username: selectedUserProfile?.Username,
      daotransaction: txnHashHex,
      userPublicKey: currentUser.PublicKeyBase58Check
    };

    try {
      const response = await fetch('https://2lcwfajr7c.execute-api.us-west-2.amazonaws.com/production/desoguard', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(postData)
      });

      if (!response.ok) {
        // Throw an error with the response status
        console.error(`Error: ${response.status}`);
        setApiError(`Error: ${response.status}`);
        return
      }

      const data = await response.json();
      console.log('API Response:', data);
      if (data.statusCode === 500) {
        let responseBody;
        try {
          responseBody = JSON.parse(data.body);
        } catch (error) {
          // Handle case where JSON parsing fails
          console.error("Error parsing response body:", error);
          setApiError("An unexpected error occurred.");
          setIsSubmitting(false);
          setSearchUserInitiated(false);
          return;
        }

        // Check for specific error message and handle accordingly
        if (responseBody && typeof responseBody === 'string' && responseBody.includes("cannot unpack non-iterable NoneType object")) {
          setApiError("Sorry, there was an error retrieving data for this profile.");
          setIsSubmitting(false);
          setSearchUserInitiated(false);
        } else {
          // Handle other potential errors or default to showing the parsed message
          setApiError(responseBody || "An unexpected error occurred.");
          setIsSubmitting(false);
          setSearchUserInitiated(false);
        }
      } else if (!response.ok) {
        // Handle non-2xx responses if any
        console.error(`Error: ${response.status}`);
        setApiError(`Error: ${response.status}`);
        setIsSubmitting(false);
        setSearchUserInitiated(false);
      }
      if (data.body) {
        // Parse the JSON string inside the `body` field
        const responseBody = JSON.parse(data.body);

        // Check if the parsed object has a `debug` field and it's not empty
        if (responseBody.debug && responseBody.debug !== "") {
          setApiDebugMessage(responseBody.debug);
        }
      }


      // Correctly parse the response body as JSON
      const responseBody = JSON.parse(data.body);
      return responseBody;

    } catch (error) {
      console.error("Error fetching data:", error);
      setApiError("Error fetching data:", error);
      return { error: `Error fetching data: ${error.message}` };
    }
  }

  async function submitUsername() {
    setSearchUserInitiated(true)
    setShowBuyTokensButton(false);
    setIsSubmitting(true);
    setIsHuman(false);
    setIsBot(false);
    setIsScam(false);
    setIsAI(false); // Reset the AI state
    setNotEnoughTransactions(false);
    setApiResponse('');
    setApiError('');
    setCreatorCoinTransactions('');
    setSelectedUserNFTBuyNowTransactions('');
    setSelectedUserNFTBidTransactions('');

    try {
      // Assuming identity is available in this scope
      if (
        !identity.hasPermissions({
          TransactionCountLimitMap: {
            NEW_MESSAGE: "UNLIMITED"
          },
          DAOCoinOperationLimitMap: {
            BC1YLiXpwSpaUt3TmsXH1cEgr3fh8S9fLKaFfj6SHTqgxHMRUC4oQEk: {
              transfer: "UNLIMITED",
            }
          }
        })
      ) {
        // if the user doesn't have permissions, request them
        // and abort the submit
        await identity.requestPermissions({
          GlobalDESOLimit: 10000000, // 0.01 DESO
          TransactionCountLimitMap: {
            NEW_MESSAGE: "UNLIMITED"
          },
          DAOCoinOperationLimitMap: {
            BC1YLiXpwSpaUt3TmsXH1cEgr3fh8S9fLKaFfj6SHTqgxHMRUC4oQEk: {
              transfer: "UNLIMITED",
            }
          }
        });
      }

      const Enums = {
        values: {
          NANO_VALUE: 1e9, // Assuming this represents 1 billion
          HEX_PREFIX: '0x'
        }
      };
      const firstAmount = 1.0 * Enums.values.NANO_VALUE * Enums.values.NANO_VALUE; // Convert amount to "nano-nanos"
      const amountNanos = new BigNumber(firstAmount); // Convert amount to BigNumber
      const hexAmount = amountNanos.toString(16); // Convert BigNumber to hexadecimal string
      const finalAmount = Enums.values.HEX_PREFIX + hexAmount; // Add hex prefix

      // Ensure transferDeSoToken is defined and correctly handles promises
      await transferDeSoToken({
        ProfilePublicKeyBase58CheckOrUsername: 'BC1YLiXpwSpaUt3TmsXH1cEgr3fh8S9fLKaFfj6SHTqgxHMRUC4oQEk',
        ReceiverPublicKeyBase58CheckOrUsername: 'BC1YLhmQDtYfMf95YRUKU15dMeWiAkMpY7i14KDYzc1k5iQ3jfA5sod',
        DAOCoinToTransferNanos: finalAmount,
        SenderPublicKeyBase58Check: currentUser.PublicKeyBase58Check
      }).then(async (response) => {
        console.log('Transfer successful:', response);
        // Update Token count:
        getTokenCount();
        // Handle responses
        const txnHashHex = response.submittedTransactionResponse.TxnHashHex;
        const promises = [
          fetchDesoGuardData(username, txnHashHex).then(processDesoGuardResponse),
          checkUserCreatorCoinTransactions(username, selectedUserBase64).then(processCreatorCoinData),
          nftBuyNowTransactions(username).then(setSelectedUserNFTBuyNowTransactions),
          fetchDiamondFollowerData(username).then(processDiamondFollowerData),
          nftBidTransactions(username).then(setSelectedUserNFTBidTransactions)
        ];

        await Promise.allSettled(promises);
      }).catch(error => {
        console.error('Error transferring token:', error);
        // Set an appropriate error message
        setApiResponse("");
        setApiError("There was a problem transferring the token. Please check your balance and permissions:", error);
        setShowBuyTokensButton(true);
        setIsSubmitting(false);
      });
    } catch (error) {
      console.error('Error during the operation:', error);
      setApiError("Error fetching data:", error);
    } finally {
      setIsSubmitting(false);
    }
  }

  const processDiamondFollowerData = (data) => {
    // Process diamonds data
    setDiamondsData({
      sent: data.diamondsSent.totalCount,
      received: data.diamondsReceived.totalCount,
    });

    // Initialize counters
    let follows = 0;
    let unfollows = 0;

    // Process follow/unfollow data
    data.transactions.nodes.forEach(node => {
      if (node.txnType === 9) { // Assuming 9 is the transaction type for follow/unfollow
        if (node.txIndexMetadata.IsUnfollow) {
          unfollows++;
        } else {
          follows++;
        }
      }
    });

    // Update state for follow/unfollow data
    setFollowUnfollowData({
      follows,
      unfollows,
    });
    // Process and set "Creator Coins Held/Holders" data
    setCreatorCoinsData({
      held: data.tokenBalances.totalCount,
      holders: data.tokenBalancesAsCreator.totalCount,
    });
  };

  function processDesoGuardResponse(desoGuardResponse) {
    // Assuming desoGuardResponse has a 'type' property that indicates the classification
    if (desoGuardResponse.type.type) {
      console.log(desoGuardResponse);
      switch (desoGuardResponse.type.type) {
        case "AI":
          setIsAI(true);
          break;
        case "Scam":
          setIsScam(true);
          break;
        case "Bot":
          setIsBot(true);
          break;
        case "Not Enough Transactions":
          setNotEnoughTransactions(true);
          break;
        default:
          setIsHuman(true);
      }
      setApiResponse(JSON.stringify(desoGuardResponse));
    } else {
      // If the response does not contain a type, consider the user as human by default
      setIsHuman(true);
    }

  }

  function processCreatorCoinData(creatorCoinData) {
    let transactionMessage = '';
    const { numberOfSelfBuys, totalBuyDeSo, numberOfSelfSells, totalSellDeSo, mostRecentSaleDate } = creatorCoinData;

    if (numberOfSelfSells === 0) {
      transactionMessage = "They have never sold their creator coin.";
    } else {
      transactionMessage = `They have sold their creator coin ${numberOfSelfSells} times, totaling ${Math.abs(totalSellDeSo).toFixed(2)} DeSo`;
      if (mostRecentSaleDate) {
        const today = new Date();
        const lastSaleDate = new Date(mostRecentSaleDate);
        const differenceInDays = Math.floor((today - lastSaleDate) / (1000 * 3600 * 24));
        transactionMessage += `. It has been ${differenceInDays} days since the last sale.`;
      }
    }

    if (numberOfSelfBuys > 0) {
      transactionMessage += ` Additionally, they have bought their own creator coin ${numberOfSelfBuys} times, totaling ${totalBuyDeSo.toFixed(2)} DeSo.`;
    }

    // Update the state with the constructed message
    setCreatorCoinTransactions(transactionMessage);
  }


  const searchUsername = async (username) => {
    console.log("searching usernames");
    try {
      if (username.trim() === '') {
        setAvailabilityMessage('');
        setUserProfiles([]);
        setApiError('');
        return;
      }

      const payload = {
        PublicKeyBase58Check: "",
        Username: "",
        UsernamePrefix: username.trim(),
        Description: "",
        OrderBy: "",
        NumToFetch: 5,
        ReaderPublicKeyBase58Check: "BC1YLfx8tRyEJ1gDLbybbiZocwrUijyyA2ydjpyXe11RakuFDs2gKmx",
        ModerationType: "",
        FetchUsersThatHODL: false,
        AddGlobalFeedBool: false
      };

      const response = await fetch('https://node.deso.org/api/v0/get-profiles', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json();

      if (data.ProfilesFound && data.ProfilesFound.length > 0) {
        setUserProfiles(data.ProfilesFound);
        setIsSearching(false);
      } else {
        setUserProfiles([]);
        setIsSearching(false);
        setAvailabilityMessage('No user profiles found.');
      }
    } catch (error) {
      setIsSearching(false);
      console.error('Error searching username:', error);
      setAvailabilityMessage('Error searching username.');
    }
  };


  const getProfilePictureUrl = (user) => {
    if (!user || !user.PublicKeyBase58Check) {
      // Return a default image URL or handle the lack of user data as needed
      return 'https://bitclout.com/assets/img/default_profile_pic.png';
    }
    return `https://diamondapp.com/api/v0/get-single-profile-picture/${user.PublicKeyBase58Check}?fallback=https://bitclout.com/assets/img/default_profile_pic.png`;
  };


  useEffect(() => {
    if (inputValue.trim() === '') {
      console.log("useEffect empty");
      setUserProfiles([]);
      setSelectedUserProfile(null);
      setApiError('');
    } else if (!isProfileSelected) {
      // Only call handleUsernameSearch if the change was not due to a profile selection
      setSelectedUserProfile(null);
      setShowSuggestions(true);
      handleUsernameSearch(inputValue);
    } else {
      // Reset the flag after handling the selection
      setIsProfileSelected(false);
    }
  }, [inputValue]);
  useEffect(() => {
    // Function to check if clicked outside of command
    function handleClickOutside(event) {
      if (commandRef.current && !commandRef.current.contains(event.target)) {
        setShowSuggestions(false);
        console.log("Click Outside")
      }
    }


    // Add event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Remove event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [commandRef]);

  useEffect(() => {
    getTokenCount();
  }, []);
  // Get new token amount
  useEffect(() => {
    if (!isLoading && currentUser) {
      console.log(currentUser.PublicKeyBase58Check);
      setTokensHeld(0);
      setTokensHeldNumber(0)
      getTokenCount();
    }
  }, [isLoading, currentUser]);

  useEffect(() => {
    if (!hasQueryStringEffectRun && searchTermFromQuery) {
      const decodedSearchTerm = decodeURIComponent(searchTermFromQuery);
      setIsQueryString(true);
      setSearchTerm(decodedSearchTerm)
      // Set the flag to true so the effect doesn't run again
      setQueryStringHasEffectRun(true);

    }
  }, [searchTermFromQuery, currentUser]);

  useEffect(() => {
    // Ensure there's a valid currentUser before submitting
    if (currentUser?.PublicKeyBase58Check && isQueryString) {
      console.log(searchTerm)
      simpleSearch(searchTerm, posterPublickey, searchNFTs, itemsPerPage);
      setIsQueryString(false);
    }
  }, [currentUser, isQueryString]);


  return (

    <div>
      {showBuyModal && (
        <Dialog open={showBuyModal} onOpenChange={(open) => {
          setShowBuyModal(open);
          if (!open) { // If the dialog is being closed
            setSelectedBuyValue(1); // Reset selectedBuyValue to 1
          }
        }}>
          <DialogTrigger asChild>
            <button className="hidden">Open</button>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle className="text-lg leading-6 font-bold text-white">
                Buy Tokens
              </DialogTitle>
              {!showFinishedBuyModal && (
                <DialogDescription className="mt-2 text-gray-300">
                  Purchase $DeSoSearchEngine tokens at just $0.001 each, and use them to search the DeSo blockchain. Each search only costs 1 token!
                </DialogDescription>
              )}
            </DialogHeader>
            {!showFinishedBuyModal ? (
              <>
                <RadioGroup
                  defaultValue="1"
                  className="grid grid-cols-4 gap-4"
                  onValueChange={setSelectedBuyValue}
                >
                  <div>
                    <RadioGroupItem
                      value="1"
                      id="1"
                      className="peer sr-only"
                      aria-label="1"
                    />
                    <Label
                      htmlFor="1"
                      className="flex flex-col items-center justify-between rounded-md border-2 border-muted bg-transparent p-4 hover:bg-accent hover:text-accent-foreground peer-data-[state=checked]:border-primary [&:has([data-state=checked])]:border-primary"
                    >
                      <h1 className="text-lg text-1xl font-extrabold">$1</h1>
                    </Label>
                  </div>
                  <div>
                    <RadioGroupItem
                      value="5"
                      id="5"
                      className="peer sr-only"
                      aria-label="5"
                    />
                    <Label
                      htmlFor="5"
                      className="flex flex-col items-center justify-between rounded-md border-2 border-muted bg-transparent p-4 hover:bg-accent hover:text-accent-foreground peer-data-[state=checked]:border-primary [&:has([data-state=checked])]:border-primary"
                    >
                      <h1 className="text-lg text-1xl font-extrabold">$5</h1>
                    </Label>
                  </div>
                  <div>
                    <RadioGroupItem
                      value="25"
                      id="25"
                      className="peer sr-only"
                      aria-label="25"
                    />
                    <Label
                      htmlFor="25"
                      className="flex flex-col items-center justify-between rounded-md border-2 border-muted bg-transparent p-4 hover:bg-accent hover:text-accent-foreground peer-data-[state=checked]:border-primary [&:has([data-state=checked])]:border-primary"
                    >
                      <h1 className="text-lg text-1xl font-extrabold">$25</h1>
                    </Label>
                  </div>
                  <div>
                    <RadioGroupItem
                      value="other"
                      id="other"
                      className="peer sr-only"
                      aria-label="Other"
                    />
                    <Label
                      htmlFor="other"
                      className="flex flex-col items-center justify-between rounded-md border-2 border-muted bg-transparent p-4 hover:bg-accent hover:text-accent-foreground peer-data-[state=checked]:border-primary [&:has([data-state=checked])]:border-primary"
                    >
                      <h1 className="text-lg text-1xl font-extrabold">Other</h1>
                    </Label>
                  </div>
                </RadioGroup>
                {selectedBuyValue === 'other' && (
                  <div className="grid w-full max-w-sm items-center gap-1.5">
                    <Label htmlFor="other">Amount</Label>
                    <Input
                      type="number"
                      id="otheramount"
                      value={otherBuyAmount}
                      onKeyDown={(e) => {
                        // Allow backspace, enter, and numeric keys
                        if (
                          e.key === 'Backspace' ||
                          e.key === 'Enter' ||
                          e.key === 'Tab' || // Allowing tab for navigation
                          (e.keyCode >= 48 && e.keyCode <= 57 && !e.shiftKey) || // Numeric keys without Shift
                          (e.keyCode >= 96 && e.keyCode <= 105) // Numpad keys
                        ) {
                          // Allow these keys
                        } else {
                          // Prevent the default action of the key press
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) => {
                        let value = e.target.value;
                        // Remove leading zeros (but allow the number "0")
                        if (value.startsWith('0') && value !== '0') {
                          value = value.replace(/^0+/, '');
                        }

                        if (value === '' || (!isNaN(value) && /^\d+$/.test(value))) {
                          setOtherBuyAmount(value);
                        }
                      }}
                      placeholder="$100"
                      step="1"
                    />
                  </div>
                )}
                <div>
                  <p className="text-xs">
                    *We will use DESO from your wallet equivalent to the amount specified using latest Coinbase Market Rates.
                  </p>
                </div>
                <div className="px-1 py-3 sm:px-2 sm:flex sm:flex-row-reverse">
                  <Button
                    type="button"
                    size="lg"
                    onClick={BuyTokens}
                    disabled={modalIsBuyingTokens}
                  >
                    {modalIsBuyingTokens ? (
                      <>
                        <Loader2 className="animate-spin" />
                        Buying...
                      </>
                    ) : (
                      'Buy'
                    )}
                  </Button>
                </div>
              </>
            ) : (
              <>
                {apiError ? (
                  <DialogDescription className="mt-2 text-red-500">
                    Error: {apiError}
                  </DialogDescription>
                ) : userHasEnoughDeSo ? (
                  <DialogDescription className="mt-2 text-green-500">
                    Purchase successful!
                  </DialogDescription>
                ) : (
                  <DialogDescription className="mt-2 text-red-500">
                    Sorry, you do not have enough DeSo to purchase the dollar amount you selected.
                  </DialogDescription>
                )}
                <div className="px-1 py-3 sm:px-2 sm:flex sm:flex-row-reverse">
                  <Button
                    type="button"
                    size="lg"
                    onClick={() => {
                      setShowBuyModal(false);
                      setApiError('');
                      setFinishedShowBuyModal(false);
                      setSelectedBuyValue(1)
                    }}
                  >
                    OK
                  </Button>
                </div>
              </>
            )}
          </DialogContent>
        </Dialog>
      )}

      {(!isLoading) && (
        <h1 className="text-1xl font-extrabold leading-tight tracking-tighter lg:text-6xl mt-1 text-center">
          DeSo Search Engine
        </h1>
      )}

      {!isLoading && !currentUser && (
        <div className="mb-4 text-center">
          <p className="mt-5 mx-2 mb-3 text-gray-400">Login with your DeSo identity and approve the derived key to use the DeSo Search Engine.</p>
          <Button onClick={() => identity.login()}>
            Login
          </Button>
        </div>
      )}
      {/* Main Search */}
      {!isLoading && currentUser && tokensHeldNumber < 1 && (
        <>
      <div className="p-4 text-center">
          <div>
                <p className="mt-3">In order to search a user you must first purchase tokens (cost per token is $0.001).</p>
                <Button
                  className="mt-2" // Add any additional styling classes you need
                  onClick={() => setShowBuyModal(true)} // Assumes setShowBuyModal is defined in your component
                >
                  Buy Tokens
                </Button>
                </div>
                </div>
      </>
      )}
          
      {!isLoading && currentUser && tokensHeldNumber >= 1 && (
        <div className="p-4 text-center">
          <div>
            <p>Token Balance: {tokensHeld}</p>
            <p className="text-sm italic">Cost is just 1 token per search</p>
            <Button
              className="mt-4" // Add any additional styling classes you need
              onClick={() => setShowBuyModal(true)} // Assumes setShowBuyModal is defined in your component
            >
              Buy Tokens
            </Button>
            
          </div>
          <div className="flex flex-col items-start w-full lg:w-1/2 gap-1.5 mx-auto mt-3 relative">
            <div className="flex w-full items-center space-x-2">
              <form
                className="flex w-full items-center space-x-2"
                onSubmit={async (e) => {
                  e.preventDefault(); // Prevent the default form submission behavior
                  await simpleSearch(searchTerm, posterPublickey, searchNFTs, itemsPerPage);
                }}
              >
                <Input
                  type="text"
                  placeholder="Search term..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="rounded-lg border shadow-md"
                />
                <Button
                  type="submit" // This button now submits the form
                  disabled={isSubmitting || loading}
                >
                  {isSubmitting || loading ? (
                    <>
                      <Loader2 className="mr-2 h-4 w-4 animate-spin" />Searching...
                    </>
                  ) : (
                    <>
                      <Search className="mr-2 h-4 w-4" />Search
                    </>
                  )}
                </Button>
              </form>

              {/* Main Search 
              <Button
                type="button"
                variant="outline"
                className="rounded-full"
                aria-label="Advanced Search" // Improving accessibility
                onClick={() => setAdvancedSearchOpen(true)}
              >
                Advanced <Plus className="ml-2 h-4 w-4" />
              </Button>
              */}

            </div>
            <Accordion type="single" collapsible className="w-full mt-0 my-1">
              <AccordionItem value="item-1">
                <AccordionTrigger className="text-sm">Advanced Search</AccordionTrigger>
                <AccordionContent>
                  <div className="text-left mb-1 mt-1">
                    Filter by Poster Username:
                  </div>
                  <Command className="rounded-lg border shadow-md" shouldFilter={false} ref={commandRef}>
                    <div className="relative">
                      <CustomCommandInput
                        placeholder="Search for a user..."
                        value={inputValue}
                        onValueChange={(newValue) => {
                          setInputValue(newValue);
                          setIsProfileSelected(false);
                        }}
                        selectedUserProfile={selectedUserProfile}
                      />
                      {inputValue && selectedUserProfile && (
                        <button
                          className="absolute inset-y-0 right-0 flex items-center pr-3"
                          onClick={() => {
                            setInputValue('');
                            setIsProfileSelected(false);
                          }}
                          aria-label="Clear input"
                        >
                          <X size={20} /> {/* Adjust the size as needed */}
                        </button>
                      )}
                    </div>

                    {showSuggestions && (
                      <CommandList>
                        {isSearching ? (
                          <CommandEmpty>Searching...</CommandEmpty>
                        ) : userProfiles.length > 0 ? (
                          <CommandGroup>
                            {userProfiles.map((profile) => (
                              <CommandItem
                                key={profile.PublicKeyBase58Check}
                                onSelect={() => handleProfileSelect(profile)}
                              >
                                <Avatar>
                                  <AvatarImage src={getProfilePictureUrl(profile)} alt={profile?.Username} />
                                  <AvatarFallback>{profile?.Username[0]}</AvatarFallback>
                                </Avatar>
                                <div className="ml-2">
                                  <p className="text-sm font-medium leading-none">
                                    {profile?.Username}
                                  </p>
                                  {/* Additional details here if needed */}
                                </div>

                              </CommandItem>
                            ))}
                          </CommandGroup>
                        ) : (
                          <CommandEmpty>No user profiles found.</CommandEmpty>
                        )}
                      </CommandList>
                    )}
                  </Command>
                  <div className="flex justify-end items-center space-x-2 mt-3">
                    <RadioGroup defaultValue="default" onValueChange={handleRadioChange}>
                      <div className="flex items-center space-x-2">
                        <RadioGroupItem value="default" id="r1" />
                        <Label htmlFor="r1">Exact Phrase</Label>
                      </div>
                      <div className="flex items-center space-x-2">
                        <RadioGroupItem value="all" id="r2" />
                        <Label htmlFor="r2">All of these words</Label>
                      </div>
                      <div className="flex items-center space-x-2">
                        <RadioGroupItem value="any" id="r2" />
                        <Label htmlFor="r2">Any of these words</Label>
                      </div>
                    </RadioGroup>
                    <Switch
                      id="include-nfts"
                      defaultChecked={searchNFTs} // For initial state, but since we want a controlled component, we might not use this.
                      onCheckedChange={(checked) => setSearchNFTs(checked)} // This is how you handle state change with @radix-ui
                    />
                    <label htmlFor="include-nfts">Include NFTs</label>
                  </div>
                </AccordionContent>
              </AccordionItem>
            </Accordion>

            <Dialog open={advancedSearchOpen} onOpenChange={setAdvancedSearchOpen}>
              <DialogContent className="gap-0 p-0 outline-none">
                <DialogHeader className="px-4 pb-4 pt-5">
                  <DialogTitle>Advanced Search</DialogTitle>
                  <DialogDescription>

                  </DialogDescription>
                </DialogHeader>
                <DialogFooter className="flex items-center border-t p-4 sm:justify-between">
                  <Button
                    type="button"
                    variant="outline"
                    className="rounded-full"
                    aria-label="Advanced Search" // Improving accessibility
                    onClick={() => setAdvancedSearchOpen(false)}
                  >
                    Save
                  </Button>

                </DialogFooter>
              </DialogContent>
            </Dialog>







            {!loading && !error && data && (
              <div className="flex w-full justify-center">
                <div className="w-full py-2">
                  {
                    data.posts && data.posts.edges.map(({ node }) => (
                      <PostCard key={node.postHash} post={node} lastSearchTerm={lastSearchTerm} />
                    ))
                  }
                  {
                    currentPage > 1 && (
                      <div className="text-center">
                        <Button onClick={handlePreviousPage} className="mt-4 black-text-button">
                          {loadingPreviousPosts ? "Loading..." : "Previous Page"}
                        </Button>
                      </div>
                    )
                  }
                  {
                    data.posts && data.posts.pageInfo.hasNextPage && (
                      <div className="text-center">
                        <Button onClick={handleNextPage} className="mt-4 black-text-button">
                          {loadingMorePosts ? "Loading..." : "Next Page"}
                        </Button>
                      </div>
                    )
                  }
                </div>
              </div>
            )}




          </div>
          <div className="flex justify-center items-center">
            {loading && <Loader2 className="animate-spin h-10 w-10 mt-3" />}
          </div>
          {error && <p>Error: {error.message}</p>}
          {message && <p className="mt-2 text-red-600">{message}</p>}
          {apiError && <div className="error-message mt-2 text-red-600">{apiError}</div>}
          {apiDebugMessage && <div className="error-message mt-2 text-red-600">{apiDebugMessage}</div>}
        </div>
      )}
    </div>
  );
};