import { DeSoIdentityProvider } from "react-deso-protocol";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./App.css";
import './tailwind.output.css';
import { Home } from "./routes/home";
import { Home2 } from "./routes/test";
import { Root } from "./routes/root";
import { SignAndSubmitTx } from "./routes/sign-and-submit-tx";
import { SwitchAccount } from "./routes/switch-account";
import { GetKeys } from "./routes/getkeys"
import { User } from "./routes/user";
import { DupeBlock } from "./routes/dupeblock";
import React, { useState } from "react";
import userPrefsStore from 'context/userPrefsStore';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';

  // Initialize Apollo Client
  const client = new ApolloClient({
    uri: 'https://desosearch-api.servertime.workers.dev',
    cache: new InMemoryCache(),
  });


const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      { path: "/", element: <Home /> },
      { path: "/dupeblock", element: <DupeBlock /> },
    ],
  },
]);

function App() {
  const [userPrefs, setUserPrefs] = useState(null);

  return (
    <React.StrictMode>
      <DeSoIdentityProvider>
        <userPrefsStore.Provider value={{ userPrefs, setUserPrefs }}>
        <ApolloProvider client={client}>
          <RouterProvider router={router}>
          </RouterProvider>
          </ApolloProvider>
        </userPrefsStore.Provider>
      </DeSoIdentityProvider>
    </React.StrictMode>
  );
};

ReactDOM.createRoot(document.getElementById("root")).render(<App />);